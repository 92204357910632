import { Flex } from '@fluentui/react-northstar';
import { NameSection } from '../EmployeeTable/EmployeeTableDetailPane/NameSection';
import { EmployeeDto } from '../../data-access/open-api/queries/models/EmployeeDto';
import { EmployeeProfileSkills } from './EmployeeProfileSkills';
import { EmployeeProfileLanguages } from './EmployeeProfileLanguages';
import { EmployeeInfoGrid } from './EmployeeInfoGrid';
import { EmployeeLanguageViewModel } from './viewModels/EmployeeLanguageViewModel';
import { LanguageLevelType } from '../../enum/LanguageLevelType';

export const EmployeeProfileDetails = ({ employee }: { employee?: EmployeeDto }) => {
  const employeeNativeLanguages =
    employee?.nativeLanguages.map<EmployeeLanguageViewModel>((l) => ({
      name: l.name,
      isExpert: true,
    })) ?? [];
  const employeeLanguages =
    employee?.languages.map<EmployeeLanguageViewModel>((l) => ({
      name: l.name,
      isExpert: LanguageLevelType[l.level] >= LanguageLevelType.C1,
    })) ?? [];
  return (
    <Flex column gap={'gap.large'}>
      {employee && (
        <>
          <NameSection
            id={employee.id}
            firstName={employee.firstName}
            lastName={employee.lastName}
            role={employee.role}
            isMentee={false}
            isIgnored={employee.isIgnored}
            photo={employee.photoUri}
            showTalentsLink={true}
          />
          <EmployeeInfoGrid
            domicile={employee.domicile}
            pensum={employee.pensum}
            manager={employee.managerName}
            department={employee.department}
            timeBalances={employee.timeBalances}
          />
          <EmployeeProfileLanguages
            languages={[...employeeNativeLanguages, ...employeeLanguages]}
          />
          <EmployeeProfileSkills skills={employee.skills} />
        </>
      )}
    </Flex>
  );
};
