import {
  Avatar,
  Button,
  ComponentSlotStyle,
  EyeIcon,
  EyeSlashIcon,
  Flex,
  FlexItem,
  LinkIcon,
  Text,
} from '@fluentui/react-northstar';
import { ReactNode, useCallback, useState, useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { env } from '../../../utils/env';
import {
  useIgnoreEmployee,
  useUnignoreEmployee,
} from '../../../data-access/hooks/commands/use-update-employee';
import { EmployeeAvatar } from '../EmployeeAvatar';
import { importantInfoFloatingStyle, menteeBadgeLabelStyle } from './nameSectionStyles';
import { app, pages, authentication } from '@microsoft/teams-js';
import jwt from 'jwt-decode';
import { runsInTeams } from '../../../utils/platform';
import { deepLinkRedirectState } from '../../../state/deepLinkRedirectState';

const ADMIN_ROLE = 'Admin';

interface NameSectionProps {
  firstName: string;
  lastName: string;
  id?: string;
  role: string;
  isMentee: boolean;
  isIgnored: boolean;
  importantInformationSection?: ReactNode;
  photo: string;
  showTalentsLink: boolean;
}

export const colorGrey: ComponentSlotStyle = (data) => ({
  color: data.theme.siteVariables.colors.grey['450'],
});

export const NameSection = ({
  firstName,
  lastName,
  role,
  isMentee,
  isIgnored,
  id,
  importantInformationSection,
  photo,
  showTalentsLink,
}: NameSectionProps) => {
  const { t } = useTranslation();
  const { mutate: ignoreEmployee } = useIgnoreEmployee();
  const { mutate: unignoreEmployee } = useUnignoreEmployee();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (app.isInitialized()) {
      const getIsAdmin = async () =>
        authentication.getAuthToken().then((token) => {
          setIsAdmin(jwt<{ roles: Array<string> }>(token).roles.includes(ADMIN_ROLE));
        });
      getIsAdmin();
    }
  }, []);

  const onTalentsLinkClick = useCallback(
    async (e: SyntheticEvent<HTMLElement, Event>) => {
      const teams = await runsInTeams();
      if (!teams) {
        alert('Link only works within Teams');
      } else {
        e.preventDefault();
        e.stopPropagation();

        const plannerApplicationId = env('REACT_APP_PLANNER_APP_ID');
        if (!plannerApplicationId) {
          alert('No configuration for REACT_APP_PLANNER_APP_ID found. Aborting.');
          return;
        }
        const talentsApplicationId = env('REACT_APP_TALENTS_APP_ID');
        if (!plannerApplicationId) {
          alert('No configuration for REACT_APP_TALENTS_APP_ID found. Aborting.');
          return;
        }

        const subPagePayload = JSON.stringify({
          // the necessary information for talents to call the `pages.navigateToApp` API to come back to planner
          employeeId: id,
          plannerAppId: plannerApplicationId,
          plannerPageId: 'index1',
          plannerSubPageId: id,
          // used by talents to decide if they already handled the redirect
          timestamp: new Date().getTime(),
        });

        deepLinkRedirectState.clearLastRedirectedEmployeeId();

        pages.navigateToApp({
          appId: talentsApplicationId,
          pageId: 'index1',
          subPageId: subPagePayload,
        });
      }
    },
    [id]
  );

  const onClickIgnoreEmployee = useCallback(() => {
    ignoreEmployee({ employeeId: id ?? '' });
  }, [id, ignoreEmployee]);

  const onClickUnignoreEmployee = useCallback(() => {
    unignoreEmployee({ employeeId: id ?? '' });
  }, [id, unignoreEmployee]);

  return (
    <Flex space="between">
      <Flex gap="gap.small">
        <EmployeeAvatar firstName={firstName} lastName={lastName} size="large" photo={photo} />
        <Flex column>
          <Text weight="semibold" size="large">
            {firstName} {lastName}
          </Text>
          <Flex gap="gap.small">
            <Text styles={colorGrey}>{role}</Text>
            {isMentee && (
              <Avatar
                size="smallest"
                name={t('employee-profile.mentee')}
                label={{ styles: menteeBadgeLabelStyle }}
              />
            )}
          </Flex>
        </Flex>
        {isAdmin && (
          <FlexItem push align="end">
            {isIgnored ? (
              <Button
                icon={<EyeSlashIcon outline />}
                onClick={onClickUnignoreEmployee}
                iconOnly
                primary
                flat
                title={t('employee-profile.unignore-employee-tooltip')}
              />
            ) : (
              <Button
                icon={<EyeIcon outline />}
                onClick={onClickIgnoreEmployee}
                iconOnly
                primary
                flat
                title={t('employee-profile.ignore-employee-tooltip')}
              />
            )}
          </FlexItem>
        )}
        {importantInformationSection && (
          <Flex styles={importantInfoFloatingStyle}>{importantInformationSection}</Flex>
        )}
      </Flex>
      {showTalentsLink && (
        <Button
          icon={<LinkIcon />}
          onClick={onTalentsLinkClick}
          content={t('employee-profile.talents-profile')}
          title={t('employee-profile.talents-profile-tooltip')}
        />
      )}
    </Flex>
  );
};
