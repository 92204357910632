import {
  Flex,
  PersonIcon,
  Table,
  Text
} from '@fluentui/react-northstar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { employeePaginationAtom, PaginationAtomState } from '../../state/atoms/paginationAtom';
import { activeFilterSelector, searchAtom, SearchAtomState } from '../../state/atoms/searchAtom';
import { sortAtom, SortAtomState } from '../../state/atoms/sortAtom';
import { convertMonthYear } from '../../utils/dateConverter';
import { SortableTableHeader } from '../Common/SortableTableHeader';
import { EmployeeAvatar } from './EmployeeAvatar';
import {
  avatarCellStyle,
  tableAvatarHeaderStyle,
  tableRootStyle,
  tableRowVariablesOverride,
} from './employeeTableStyles';
import { useEmployeeListData } from './hooks/useEmployeeListData';
import { Pagination } from './Pagination';

export const EmployeeTable = ({
  selectedEmployee,
  onSelectedEmployeeChanged,
}: {
  selectedEmployee?: string;
  onSelectedEmployeeChanged: (id: string) => void;
}) => {
  const { t } = useTranslation();

  const [paginationData, setPaginationData] =
    useRecoilState<PaginationAtomState>(employeePaginationAtom);
  const filterData = useRecoilValue<SearchAtomState>(searchAtom);
  const [sortData, setSortData] = useRecoilState<SortAtomState>(sortAtom);
  const activeFilter = useRecoilValue(activeFilterSelector);

  const data = useEmployeeListData({
    searchString: filterData.searchString,
    filteringOptions: activeFilter,
    sortingOptions: sortData,
    paginationOptions: paginationData,
  });

  useEffect(() => {
    if (
      data.data.length > 0 &&
      (!selectedEmployee || data.data.filter((d) => d.id === selectedEmployee).length === 0)
    ) {
      onSelectedEmployeeChanged(data.data[0].id);
    }
  }, [selectedEmployee, data, onSelectedEmployeeChanged]);

  const headers = {
    items: [
      {
        key: 'avatarHeader',
        content: <PersonIcon outline />,
        styles: tableAvatarHeaderStyle,
      },
      {
        key: 'nameHeader',
        content: <Text weight="semibold" content={t('employee-overview-table.headers.name')} />,
      },
      {
        key: 'roleHeader',
        content: <Text weight="semibold" content={t('employee-overview-table.headers.role')} />,
      },
      {
        key: 'capacityHeader',
        content: (
          <SortableTableHeader
            title={t('employee-overview-table.headers.capacity')}
            sortBy="capacity"
            currentSort={{
              sortBy: sortData.sortBy,
              sortDirection: sortData.sortDescending ? 'desc' : 'asc',
            }}
          />
        ),
        onClick: () => {
          setSortData((prev) => {
            return {
              sortBy: 'capacity',
              sortDescending: prev.sortBy === 'capacity' ? !prev.sortDescending : true,
            };
          });
        },
        style: { cursor: 'pointer' },
      },
      {
        key: 'departmentHeader',
        content: (
          <SortableTableHeader
            title={t('employee-overview-table.headers.department')}
            sortBy="department"
            currentSort={{
              sortBy: sortData.sortBy,
              sortDirection: sortData.sortDescending ? 'desc' : 'asc',
            }}
          />
        ),
        onClick: () => {
          setSortData((prev) => {
            return {
              sortBy: 'department',
              sortDescending: prev.sortBy === 'department' ? !prev.sortDescending : true,
            };
          });
        },
        style: { cursor: 'pointer' },
      },
    ],
  };

  return (
    <Flex column style={{ flexGrow: 1 }}>
      <Table
        styles={tableRootStyle}
        header={headers}
        rows={data.data.map((item) => ({
          key: item.id,
          'aria-selected': item.id === selectedEmployee,
          items: [
            {
              key: `${item.id}_avatar`,
              truncateContent: true,
              content: (
                <EmployeeAvatar
                  firstName={item.firstName}
                  lastName={item.lastName}
                  size="small"
                  photo={item.thumbnail}
                />
              ),
              styles: avatarCellStyle,
              onClick: (e: MouseEvent) => {
                onSelectedEmployeeChanged(item.id);
                e.stopPropagation();
              },
            },
            {
              key: `${item.id}_name`,
              truncateContent: true,
              content: <Text size="small" content={`${item.firstName} ${item.lastName}`} />,
            },
            {
              key: `${item.id}_role`,
              truncateContent: true,
              content: <Text size="small" content={`${item.role}${item.isMentee ? ' (M)' : ''}`} />,
            },
            {
              key: `${item.id}_load`,
              truncateContent: true,
              content: item.hasResigned ? (
                <Text size="small" content={t('employee-overview-table.resigned-text')} />
              ) : (
                <Flex gap="gap.small">
                  <Text size="small">{convertMonthYear(item.capacityDate)}</Text>
                  <Text
                    size="small"
                    weight="semibold"
                    content={`${Math.round((item.capacity + Number.EPSILON) * 100) / 100}%`}
                  />
                </Flex>
              ),
            },
            {
              key: `${item.id}_department`,
              truncateContent: true,
              content: <Text size="small" content={item.department} />,
            },
          ],
          onClick: () => onSelectedEmployeeChanged(item.id),
          variables: tableRowVariablesOverride,
        }))}
      ></Table>
      <Pagination
        currentPage={paginationData.currentPage}
        pageSize={paginationData.pageSize}
        totalItems={data.totalItems}
        onPageChange={(page) =>
          setPaginationData((prev) => {
            return {
              ...prev,
              currentPage: page,
            };
          })
        }
      />
    </Flex>
  );
};
