import { Flex, Grid, Status, Text } from '@fluentui/react-northstar';
import { gridSeperator } from './employeeProfileStyles';
import { statusStyle, statusVariables } from '../Common/statusStyles';
import { EmployeeMonthlyWorkloadDto } from '../../data-access/open-api/queries';
import { customFormatter } from '../../utils/customFormatter';
import dayjs from 'dayjs';

export const EmployeeProfileProjectsGrid = ({
  employeeProjects,
  pensum,
}: {
  employeeProjects?: Array<EmployeeMonthlyWorkloadDto>;
  employeeId?: string;
  pensum?: number;
}) => {
  const getStatusValue = (monthlyWorkloads: EmployeeMonthlyWorkloadDto) => {
    const capacity = monthlyWorkloads.percentage;
    if (monthlyWorkloads.projects.length === 0) {
      return 'error';
    } else if (capacity !== 0 && capacity !== (pensum ?? 100)) {
      return 'warning';
    } else if (capacity === 0) {
      return 'success';
    } else if (capacity === pensum ?? 100) {
      return 'error';
    } else {
      return 'unknown';
    }
  };

  const projectLoad = (monthlyWorkloads: EmployeeMonthlyWorkloadDto): number => {
    if (monthlyWorkloads.projects.length !== 0)
      return Math.round((pensum ?? 100) - monthlyWorkloads.percentage);
    else return 0;
  };

  const projectDurationFormatter = (projectStart: string, projectEnd:string) => {
    if (projectStart == projectEnd) {
      return customFormatter(projectStart);
    } else {
      return `${customFormatter(projectStart)} - ${customFormatter(projectEnd)}`;
    }
  };

  return (
    <>
      <Grid columns="3" style={{ width: '100%', gap: '21px' }}>
        {employeeProjects &&
          employeeProjects.map((projectGroup) => (
            <Flex
              key={`${projectGroup.year} ${projectGroup.month}`}
              column
              style={{ width: '100%', gap: '22px' }}
              styles={gridSeperator}
            >
              <Flex>
                <Flex column style={{ gap: '4px' }}>
                  <Text size="large">
                    {dayjs(new Date(projectGroup.year, projectGroup.month)).format('MMMM YYYY')}
                  </Text>
                  <Flex
                    key={`project_status_${projectGroup.month}_${projectGroup.year}`}
                    gap="gap.smaller"
                    vAlign="center"
                  >
                    <Status
                      styles={statusStyle}
                      size="larger"
                      variables={statusVariables}
                      state={projectGroup.hasResigned ? 'unknown' : getStatusValue(projectGroup)}
                    />
                    <Text size="small" weight="semibold">
                      {projectGroup.hasResigned
                        ? 'Left'
                        : `${projectLoad(projectGroup)} / ${pensum ?? 100} %`}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex column gap="gap.smaller">
                {projectGroup.projects
                  .sort((a, b) => (b.percentage ?? 0) - (a.percentage ?? 0))
                  .map((project) => (
                    <Flex gap="gap.smaller" key={project.projectId}>
                      <Text align="end" size="small" style={{ minWidth: '3rem' }}>
                        {project.percentage} %
                      </Text>
                      <Text size="small">
                        {project.projectName}
                        <br />
                        {projectDurationFormatter(project.projectStart, project.projectEnd)}
                      </Text>
                    </Flex>
                  ))}
              </Flex>
            </Flex>
          ))}
      </Grid>
    </>
  );
};
