/**
 * This file provides a wrapper around state in localStorage
 * that is used to implement the 'deep back link' feature from Talents to a Planner profile.
 * 
 * The reason, why this state is in localStorage (instead of e.g. an atom or other kinds of state) is this:
 * When switching between tabs the regular javascript state is lost, 
 * but the app.context.page.subPageId (which holds the information to redirect to the employee page) does *not* get reset.
 * 
 * So to remember, if the redirect was already handled a more persistent state was needed.
 */


const key = 'lastRedirectedEmployeeId';

export const deepLinkRedirectState = {
    clearLastRedirectedEmployeeId: () => localStorage.removeItem(key),
    setLastRedirectedEmployeeId: (employeeId: string) => localStorage.setItem(key, employeeId),
    getLastRedirectedEmployeeId: () => localStorage.getItem(key),
}