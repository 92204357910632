import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeProjectAssignmentDto } from '../../../data-access/open-api/queries';
import { EmployeeProfileAddProjectDialog } from '../AddProjectDialog/EmployeeProfileAddProjectDialog';
import { EmployeeProfileViewModel } from '../viewModels/EmployeeProfileViewModel';
import { convertToMonthDayYearSlash } from '../../../utils/dateConverter';
import { ProbabilityOfExtensionType } from '../../../enum/ProbabilityOfExtensionType';
import { Ti8mDialog } from '../../Common/Ti8mDialog';

interface EditEmployeeProjectDialogProps {
  isDialogOpen: boolean;
  employeeProject: EmployeeProjectAssignmentDto;
  handleCloseDialog: () => void;
  handleConfirmDialog: (project: EmployeeProjectAssignmentDto) => void;
}

export const EditEmployeeProjectDialog = ({
  isDialogOpen,
  employeeProject,
  handleCloseDialog,
  handleConfirmDialog,
}: EditEmployeeProjectDialogProps) => {
  const { t } = useTranslation();
  const [isTouched, setTouched] = useState<boolean>(false);
  const [project, setProject] = useState<EmployeeProfileViewModel>({
    ...employeeProject,
    projectId: employeeProject.projectId ?? '',
    workloads: [
      ...employeeProject.workloads.map((v) => {
        return { ...v, hasErrorStart: false, hasErrorEnd: false };
      }),
    ],
  });

  const checkConfirmButtonEnabled = useCallback(
    (project: EmployeeProfileViewModel) => {
      if (project.workloads) {
        let workloadsOrdered = project.workloads
          .filter((e) => e.end !== '' || e.start !== '' || e.percentage !== 0)
          .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
        workloadsOrdered.forEach((e) => {
          e.hasErrorEnd = false;
          e.hasErrorStart = false;
        });
        let touched = workloadsOrdered.length > 0;
        workloadsOrdered.forEach((e, i, ws) => {
          if (e.end === '' || e.start === '' || e.percentage === 0) {
            touched = false;
          }
          if (ws.length > i + 1) {
            const currentStart = new Date(e.start);
            const currentEnd = new Date(e.end);
            const nextStart = new Date(ws[i + 1].start);
            const nextEnd = new Date(ws[i + 1].end);
            if (currentStart <= nextStart && currentEnd >= nextEnd) {
              touched = false;
              e.hasErrorStart = true;
              e.hasErrorEnd = true;
              ws[i + 1].hasErrorStart = true;
              ws[i + 1].hasErrorEnd = true;
            } else if (
              currentEnd >= nextStart ||
              (currentEnd.getMonth() + 1) % 12 !== nextStart.getMonth()
            ) {
              touched = false;
              e.hasErrorEnd = true;
              ws[i + 1].hasErrorStart = true;
            }
          }
        });
        setTouched(touched);
      } else {
        setTouched(false);
      }
    },
    [setTouched]
  );

  const updateProject = useCallback(
    (data: any, property: 'workloads' | 'projectId' | 'probabilityOfExtension') => {
      setProject((prev: EmployeeProfileViewModel) => ({
        ...prev,
        [property]: data,
      }));

      setTouched(true);
    },
    [setProject, setTouched]
  );

  const setStartDate = useCallback(
    (data: Date | null, i: number) => {
      let workloads = project.workloads;
      if (workloads) {
        workloads[i].start = data ? convertToMonthDayYearSlash(data) : '';
      }

      updateProject(workloads, 'workloads');
      checkConfirmButtonEnabled({
        ...project,
        workloads: workloads,
      });
    },
    [project, updateProject, checkConfirmButtonEnabled]
  );

  const setEndDate = useCallback(
    (data: Date | null, i: number) => {
      let workloads = project.workloads;
      if (workloads) {
        workloads[i].end = data ? convertToMonthDayYearSlash(data) : '';
      }

      updateProject(workloads, 'workloads');
      checkConfirmButtonEnabled({
        ...project,
        workloads: workloads,
      });
    },
    [project, updateProject, checkConfirmButtonEnabled]
  );

  const setPensum = useCallback(
    (data: string, i: number) => {
      let workloads = project.workloads;
      if (workloads) {
        workloads[i].percentage = parseFloat(data);
      }
      updateProject(workloads, 'workloads');
    },
    [project, updateProject]
  );

  const setProbabilityOfExtension = useCallback(
    (data?: ProbabilityOfExtensionType) => updateProject(data, 'probabilityOfExtension'),
    [updateProject]
  );

  const addWorkload = useCallback(() => {
    let workloads = project.workloads;
    if (workloads) {
      workloads.push({
        percentage: 0,
        start: '',
        end: '',
        hasErrorStart: false,
        hasErrorEnd: false,
      });
    } else {
      workloads = [
        {
          percentage: 0,
          start: '',
          end: '',
          hasErrorStart: false,
          hasErrorEnd: false,
        },
      ];
    }

    updateProject(workloads, 'workloads');
  }, [project, updateProject]);

  const removeWorkload = useCallback(
    (i: number) => {
      let workloads = project.workloads;
      if (workloads) {
        delete workloads[i];
      }

      updateProject(
        workloads?.filter((w) => w !== null),
        'workloads'
      );
      checkConfirmButtonEnabled({
        ...project,
        workloads: workloads,
      });
    },
    [project, updateProject, checkConfirmButtonEnabled]
  );

  const onCloseDialog = useCallback(() => {
    handleCloseDialog();
  }, [handleCloseDialog]);

  const onConfirmDialog = useCallback(() => {
    handleConfirmDialog(project);
  }, [handleConfirmDialog, project]);

  return (
    <Ti8mDialog
      open={isDialogOpen}
      header={project.projectName}
      cancelButton={{
        content: t('employee-profile.projects.dialog.button-cancel'),
      }}
      onCancel={() => onCloseDialog()}
      confirmButton={{
        content: t('employee-profile.projects.dialog.button-confirm'),
        disabled: !isTouched,
      }}
      onConfirm={() => onConfirmDialog()}
      content={
        <EmployeeProfileAddProjectDialog
          project={project}
          addWorkload={addWorkload}
          removeWorkload={removeWorkload}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setPensum={setPensum}
          setProbabilityOfExtension={setProbabilityOfExtension}
        />
      }
    />
  );
};
