import {
  AddIcon,
  Button,
  ChevronEndIcon,
  ChevronStartIcon,
  EditIcon,
  Flex,
  Text,
} from '@fluentui/react-northstar';
import { panelHeadingStyle, arrowButtonStyle } from './employeeProfileStyles';
import { useTranslation } from 'react-i18next';
import { EmployeeProjectsFilter, useEmployeeProjectsData } from './hooks/useEmployeeProjectData';
import { useCallback, useEffect, useState } from 'react';
import { EmployeeProfileViewModel } from './viewModels/EmployeeProfileViewModel';
import { EmployeeProfileAddProjectDialog } from './AddProjectDialog/EmployeeProfileAddProjectDialog';
import {
  useAddEmployeeProject,
  useUpdateEmployeeProjects,
} from '../../data-access/hooks/commands/use-update-employee';
import { EmployeeProfileProjectsGrid } from './EmployeeProfileProjectsGrid';
import { ProjectsListToEditDialog } from './EditProjectsDialog/ProjectsListToEditDialog';
import {
  EmployeeMonthlyWorkloadDto,
  EmployeeProjectAssignmentDto,
} from '../../data-access/open-api/queries';
import { useEmployeeMonthlyWorkloadsData } from './hooks/useEmployeeMonthlyWorkloadsData';
import { EmployeeProjectAssignmentsChangesDto } from '../../data-access/open-api/commands/models/EmployeeProjectsChangesDto';
import dayjs from 'dayjs';
import { convertToMonthDayYearSlash } from '../../utils/dateConverter';
import { ProbabilityOfExtensionType } from '../../enum/ProbabilityOfExtensionType';
import { Ti8mDialog } from '../Common/Ti8mDialog';

export const EmployeeProfileProjects = ({
  employeeId,
  pensum,
}: {
  employeeId?: string;
  pensum?: number;
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<EmployeeProjectsFilter>({
    id: employeeId,
    startDate: new Date(new Date().setDate(1)),
    endDate: new Date(
      new Date(new Date(new Date().setDate(1)).setMonth(new Date().getMonth() + 3)).getFullYear(),
      new Date(new Date(new Date().setDate(1)).setMonth(new Date().getMonth() + 3)).getMonth(),
      0
    ),
  });
  const [isAddProjectsDialogOpen, setAddProjectsDialogOpen] = useState<boolean>(false);
  const [isListProjectsDialogOpen, setListProjectsDialogOpen] = useState<boolean>(false);
  const [isTouched, setTouched] = useState<boolean>(false);
  const [newProjectData, setNewProjectData] = useState<EmployeeProfileViewModel>({
    projectId: '',
    workloads: [
      {
        start: '',
        end: '',
        percentage: 0,
        hasErrorStart: false,
        hasErrorEnd: false,
      },
    ],
    probabilityOfExtension: undefined,
  });
  const [employeeMonthlyWorkloads, setEmployeeMonthlyWorkloads] = useState<
    Array<EmployeeMonthlyWorkloadDto>
  >(
    Array.from(new Array(3), (_, i) => {
      const date = new Date(filter.startDate.getFullYear(), filter.startDate.getMonth() + i);
      return {
        month: date.getMonth(),
        year: date.getFullYear(),
        percentage: 0,
        projects: [],
      };
    })
  );
  const { mutate: mutateEmployees } = useAddEmployeeProject(
    t('alert.request-objects.employee'),
    t('alert.update-types.edit')
  );

  const { mutate: mutateEmployeeProjects } = useUpdateEmployeeProjects(
    t('alert.request-objects.employee'),
    t('alert.update-types.edit')
  );

  const employeeProjectsData = useEmployeeProjectsData({
    filteringOptions: { ...filter },
  });

  const employeeMonthlyWorkloadsData = useEmployeeMonthlyWorkloadsData({
    filteringOptions: { ...filter },
  });

  const [employeeProjects, setEmployeeProjectsData] =
    useState<Array<EmployeeProjectAssignmentDto>>();

  // Prevents box-size change(flicker) on employee data change
  useEffect(() => {
    if (employeeProjectsData) {
      setEmployeeProjectsData(employeeProjectsData);
    }
    if (employeeMonthlyWorkloadsData) {
      setEmployeeMonthlyWorkloads(
        Array.from(new Array(3), (_, i) => {
          const date = new Date(filter.startDate.getFullYear(), filter.startDate.getMonth() + i);
          if (employeeMonthlyWorkloadsData.length > 0) {
            let result = employeeMonthlyWorkloadsData.find(
              (p) => p.month === date.getMonth() && p.year === date.getFullYear()
            );
            if (result) {
              return result;
            }
          }
          return {
            month: date.getMonth(),
            year: date.getFullYear(),
            percentage: 0,
            projects: [],
          };
        })
      );
    }
  }, [
    filter,
    employeeMonthlyWorkloadsData,
    employeeProjectsData,
    setEmployeeMonthlyWorkloads,
    setEmployeeProjectsData,
  ]);

  const forward = useCallback(() => {
    setFilter((prev) => ({
      ...prev,
      startDate: new Date(new Date(prev.startDate).setMonth(prev.startDate.getMonth() + 1)),
      endDate: new Date(
        new Date(
          new Date(new Date(prev.endDate).setDate(1)).setMonth(
            new Date(prev.endDate).getMonth() + 2
          )
        ).getFullYear(),
        new Date(
          new Date(new Date(prev.endDate).setDate(1)).setMonth(
            new Date(prev.endDate).getMonth() + 2
          )
        ).getMonth(),
        0
      ),
    }));
  }, [setFilter]);

  const back = useCallback(() => {
    setFilter((prev) => ({
      ...prev,
      startDate: new Date(new Date(prev.startDate).setMonth(prev.startDate.getMonth() - 1)),
      endDate: new Date(
        new Date(new Date(prev.endDate).setMonth(prev.endDate.getMonth())).getFullYear(),
        new Date(new Date(prev.endDate).setMonth(prev.endDate.getMonth())).getMonth(),
        0
      ),
    }));
  }, [setFilter]);

  const updateNewProjectData = useCallback(
    (data: any, property: 'workloads' | 'projectId' | 'probabilityOfExtension') => {
      setNewProjectData({
        ...newProjectData,
        [property]: data,
      });
      checkConfirmButtonEnabled({
        ...newProjectData,
        [property]: data,
      });
    },
    [newProjectData, setNewProjectData]
  );

  const resetProjectState = useCallback(() => {
    setNewProjectData({
      projectId: '',
      workloads: [
        {
          start: '',
          end: '',
          percentage: 0,
          hasErrorStart: false,
          hasErrorEnd: false,
        },
      ],
      probabilityOfExtension: undefined,
    });
  }, [setNewProjectData]);

  const onConfirmAddingProject = useCallback(() => {
    mutateEmployees({
      employeeId: employeeId ?? '',
      requestBody: {
        projectId: newProjectData.projectId,
        workloads: newProjectData.workloads,
        probabilityOfExtension: newProjectData.probabilityOfExtension,
      },
    });
    resetProjectState();
  }, [employeeId, newProjectData, mutateEmployees, resetProjectState]);

  const checkConfirmButtonEnabled = (project: EmployeeProfileViewModel) => {
    if (project.projectId !== '' && project.workloads) {
      let workloadsOrdered = project.workloads
        .filter((e) => e.end !== '' || e.start !== '' || e.percentage !== 0)
        .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
      workloadsOrdered.forEach((e) => {
        e.hasErrorEnd = false;
        e.hasErrorStart = false;
      });
      let touched = workloadsOrdered.length > 0;
      workloadsOrdered.forEach((e, i, ws) => {
        if (e.end === '' || e.start === '' || e.percentage === 0) {
          touched = false;
        }
        if (ws.length > i + 1) {
          const currentStart = new Date(e.start);
          const currentEnd = new Date(e.end);
          const nextStart = new Date(ws[i + 1].start);
          const nextEnd = new Date(ws[i + 1].end);
          if (
            e.start &&
            e.start !== '' &&
            ws[i + 1].start &&
            ws[i + 1].start !== '' &&
            e.end &&
            e.end !== '' &&
            ws[i + 1].end &&
            ws[i + 1].end !== '' &&
            currentStart <= nextStart &&
            currentEnd >= nextEnd
          ) {
            touched = false;
            e.hasErrorStart = true;
            e.hasErrorEnd = true;
            ws[i + 1].hasErrorStart = true;
            ws[i + 1].hasErrorEnd = true;
          } else if (
            ws[i + 1].start &&
            ws[i + 1].start !== '' &&
            e.end &&
            e.end !== '' &&
            (currentEnd >= nextStart ||
              new Date(
                currentEnd.getFullYear(),
                currentEnd.getMonth(),
                currentEnd.getDate() + 1
              ).getTime() !== nextStart.getTime())
          ) {
            touched = false;
            e.hasErrorEnd = true;
            ws[i + 1].hasErrorStart = true;
          }
        }
      });
      setTouched(touched);
    } else {
      setTouched(false);
    }
  };

  const onConfirmEditProjectList = useCallback(
    (changes: EmployeeProjectAssignmentsChangesDto) => {
      setListProjectsDialogOpen(false);
      mutateEmployeeProjects({
        employeeId: employeeId ?? '',
        requestBody: changes,
      });
    },
    [setListProjectsDialogOpen, mutateEmployeeProjects, employeeId]
  );

  const onCancelEditProjectList = useCallback(() => {
    setListProjectsDialogOpen(false);
  }, [setListProjectsDialogOpen]);

  return (
    <Flex column gap={'gap.large'}>
      <Flex column gap={'gap.medium'}>
        <Text weight="semibold" size="large" styles={panelHeadingStyle}>
          {t('employee-profile.projects.projects')}
        </Text>
        <Flex gap="gap.medium">
          <Button
            icon={<AddIcon />}
            iconPosition="before"
            content={t('employee-profile.projects.add-project')}
            primary
            flat
            onClick={() => setAddProjectsDialogOpen(true)}
          />
          {employeeProjects && (
            <>
              <Button
                icon={<EditIcon />}
                iconPosition="before"
                content={t('employee-profile.projects.dialog.edit-projects')}
                flat
                disabled={
                  employeeMonthlyWorkloadsData ? employeeMonthlyWorkloadsData.length <= 0 : true
                }
                onClick={() => setListProjectsDialogOpen(true)}
              />
              <ProjectsListToEditDialog
                key={`listProjectDialog_${isListProjectsDialogOpen}`}
                isDialogOpen={isListProjectsDialogOpen}
                employeeProjects={employeeProjects}
                onHandleClose={onCancelEditProjectList}
                onHandleSave={onConfirmEditProjectList}
              />
            </>
          )}
          <Ti8mDialog
            open={isAddProjectsDialogOpen}
            header={t('employee-profile.projects.add-project')}
            cancelButton={{
              content: t('employee-profile.projects.dialog.button-cancel'),
            }}
            onCancel={() => {
              resetProjectState();
              setAddProjectsDialogOpen(false);
              setTouched(false);
            }}
            confirmButton={{
              content: t('employee-profile.projects.dialog.button-confirm'),
              disabled: !isTouched,
            }}
            onConfirm={() => {
              setAddProjectsDialogOpen(false);
              setTouched(false);
              onConfirmAddingProject();
            }}
            content={
              <EmployeeProfileAddProjectDialog
                project={newProjectData}
                setProject={(data: string) => {
                  updateNewProjectData(data, 'projectId');
                }}
                addWorkload={() => {
                  let workloads = newProjectData.workloads;
                  workloads.push({
                    percentage: 0,
                    start: '',
                    end: '',
                    hasErrorStart: false,
                    hasErrorEnd: false,
                  });

                  setNewProjectData({
                    ...newProjectData,
                    workloads: workloads,
                  });
                }}
                removeWorkload={(i: number) => {
                  let workloads = newProjectData.workloads;
                  delete workloads[i];
                  setNewProjectData({
                    ...newProjectData,
                    workloads: workloads.filter((w) => w !== null),
                  });
                  checkConfirmButtonEnabled({
                    ...newProjectData,
                    workloads: workloads,
                  });
                }}
                setStartDate={(data: Date | null, i: number) => {
                  let workloads = newProjectData.workloads;
                  workloads[i].start = data ? convertToMonthDayYearSlash(data) : '';

                  updateNewProjectData(workloads, 'workloads');
                }}
                setEndDate={(data: Date | null, i: number) => {
                  let workloads = newProjectData.workloads;
                  workloads[i].end = data ? convertToMonthDayYearSlash(data) : '';

                  updateNewProjectData(workloads, 'workloads');
                }}
                setPensum={(data: string, i: number) => {
                  let workloads = newProjectData.workloads;
                  workloads[i].percentage = parseFloat(data);

                  updateNewProjectData(workloads, 'workloads');
                }}
                setProbabilityOfExtension={(data?: ProbabilityOfExtensionType) => {
                  updateNewProjectData(data, 'probabilityOfExtension');
                }}
              />
            }
            resetOverflow
          />
        </Flex>
      </Flex>
      <Flex hAlign={'center'}>
        <Button styles={arrowButtonStyle} text onClick={back}>
          <ChevronStartIcon />
        </Button>
        <Flex hAlign="center" style={{ minWidth: '14rem' }}>
          <Text styles={panelHeadingStyle} size="large" weight="semibold">
            {`${dayjs(filter.startDate).format('MMMM')} - ${dayjs(filter.endDate).format(
              'MMMM YYYY'
            )}`}
          </Text>
        </Flex>
        <Button styles={arrowButtonStyle} text onClick={forward}>
          <ChevronEndIcon />
        </Button>
      </Flex>
      <EmployeeProfileProjectsGrid
        employeeProjects={employeeMonthlyWorkloads}
        employeeId={employeeId}
        pensum={pensum}
      />
    </Flex>
  );
};
