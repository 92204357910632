import React, { FunctionComponent, useEffect, useState } from 'react';
import { SearchWithFilter } from '../../components/SearchWithFilter/SearchWithFilter';
import BasicLayout from '../../components/Layout/Layout';
import { EmployeeTable } from '../../components/EmployeeTable/EmployeeTable';
import { Flex } from '@fluentui/react-northstar';
import { EmployeeTableDetailPane } from '../../components/EmployeeTable/EmployeeTableDetailPane/EmployeeTableDetailPane';
import { generatePath, useParams, useHistory } from 'react-router-dom';
import { app } from '@microsoft/teams-js';
import { runsInTeams } from '../../utils/platform';
import { deepLinkRedirectState } from '../../state/deepLinkRedirectState';

export const IndexPage: FunctionComponent = () => {
  const { employeeId } = useParams<{ employeeId?: string }>();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | undefined>(employeeId);

  const history = useHistory();

  useEffect(() => {
    // In *Talents* there is a link (back) to Planner.
    // Talents however can't target the employee page directly, but only a specific tab.
    // -> If we are targeted by Talents: redirect to the Employee Profile Page (if we haven't done so yet).
    const redirectFromTalents = async () => {
      const inTeams = await runsInTeams();
      if (inTeams) {
        app.getContext().then((context) => {
          const employeeId = context.page.subPageId;
          const lastRedirected = deepLinkRedirectState.getLastRedirectedEmployeeId();

          if (employeeId && employeeId !== lastRedirected) {
            deepLinkRedirectState.setLastRedirectedEmployeeId(employeeId);
            const url = generatePath('/employeeDetails/:employeeId', { employeeId: employeeId });
            history.replace(url);
          }
        });
      }
    };
    redirectFromTalents();
  }, [history]);

  return (
    <BasicLayout>
      <SearchWithFilter />
      <Flex>
        <EmployeeTable
          onSelectedEmployeeChanged={setSelectedEmployeeId}
          selectedEmployee={selectedEmployeeId}
        />
        <EmployeeTableDetailPane employeeId={selectedEmployeeId} />
      </Flex>
    </BasicLayout>
  );
};
