import { Flex, Text, Input, TextArea } from '@fluentui/react-northstar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { textAreaStyle } from '../employeeProfileStyles';

export const TemplateStatesDialog = ({
  projectChangeRequest,
  openEducation,
  notes,
  onPropertyChanged,
}: {
  projectChangeRequest?: string;
  openEducation?: string;
  notes?: string;
  onPropertyChanged: (prop: string, value: any) => void;
}) => {
  const { t } = useTranslation();
  const [notesCharCount, setNotesCharCount] = useState(notes?.length);
  const [projectChangeRequestValue, setProjectChangeRequestValue] = useState(projectChangeRequest);
  const [openEducationValue, setOpenEducationValue] = useState(openEducation);

  return (
    <Flex column gap="gap.medium">
      <Flex column gap="gap.smaller">
        <Text weight="semibold">{t('employee-profile.project-change-request')}</Text>
        <Input
          fluid
          defaultValue={projectChangeRequest}
          value={projectChangeRequestValue}
          placeholder={t('employee-profile.placeholder-request')}
          onChange={(_, data) => {
            setProjectChangeRequestValue(data?.value ?? '');
            onPropertyChanged('projectChangeRequest', data?.value ?? '');
          }}
          clearable
        />
      </Flex>
      <Flex column gap="gap.smaller">
        <Text weight="semibold">{t('employee-profile.open-education')}</Text>
        <Input
          fluid
          placeholder={t('employee-profile.placeholder-education')}
          defaultValue={openEducation}
          value={openEducationValue}
          onChange={(_, data) => {
            setOpenEducationValue(data?.value ?? '');
            onPropertyChanged('openEducation', data?.value ?? '');
          }}
          clearable
        />
      </Flex>
      <Flex column gap="gap.smaller">
        <Text weight="semibold">{t('employee-profile.notes')}</Text>
        <TextArea
          styles={textAreaStyle}
          rows={4}
          inverted
          defaultValue={notes}
          placeholder={t('employee-profile.placeholder-notes')}
          maxLength={200}
          onChange={(_, data) => {
            onPropertyChanged('notes', data?.value ?? '');
            setNotesCharCount(data?.value?.length ?? 0);
          }}
        />
        <Text align="end" size="small">{`${notesCharCount ?? 0}/200`}</Text>
      </Flex>
    </Flex>
  );
};
